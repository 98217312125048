import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { buildListMessagesUrl } from 'state-domains/network';
import { sendRetrieveRequest } from 'state-domains/utils';

import { MessagesPayload, NSQueryProps } from '../types';

import { translateAllMessages, getRequestOverrides } from './translators';
import { APIAllMessages } from './types';

function responseHandler(response: APIAllMessages): Observable<MessagesPayload> {
    const result = translateAllMessages(response);
    return observableOf(result);
}

export function loadMessages(
    token: string,
    audienceId: string,
    queryProps: NSQueryProps,
): Observable<MessagesPayload> {
    const url = buildListMessagesUrl(audienceId, queryProps);
    const ajax = sendRetrieveRequest(url, getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => responseHandler(response)));
}

export function loadMessagesLink(token: string, url: string): Observable<MessagesPayload> {
    const ajax = sendRetrieveRequest(url, getRequestOverrides(token));
    return ajax.pipe(switchMap(({ response }) => responseHandler(response)));
}
